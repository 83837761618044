import {Component, Input, OnInit} from '@angular/core';
import {toggleBar} from '../../../../assets/js';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
    @Input('loading') loading: boolean = false;

    constructor() {
    }

    ngOnInit() {
    }
}
