import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

const routes: Routes = [
    {
        path: '',
        loadChildren: './modules/reservation/reservation.module#ReservationModule'
    },
    {path: '**', redirectTo: ''},
];


@NgModule({
    imports: [BrowserModule, RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRouter {
}
