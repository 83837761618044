import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as glob from '../../../../../shared/global';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchReservService {

    constructor(private _httpClient: HttpClient) {
    }

    public data: any = {};
    public dataStep1: any = {};
    public dataStep2: any = {};
    public dataStep3: any = {};


    // Search Reservation

    GetCityPair(): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}booking/country`);
    }

    GetCityPairArrival(cityPare): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}booking/list-pair-arrival?pair= ${cityPare}`);
    }

    GetNotification(): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}agency/notification`);
    }

    SearchFlight(formData): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/search-flight`, formData);
    }

    SearchFlowestFare(formData): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/search-lowest-fare`, formData);

    }

    getDataCurrency(): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}booking/currencies`).pipe(map(res => {
            return res;
        }));
    }

    // 3

    SendRequestBooking(formData): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/send-request-booking`, formData);
    }

    ChangeDateSelectFlight(formData): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/change-day-search-flight`, formData);
    }

    // 4

    ConfirmRequestBooking(formData): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/confirm-send-request-booking`, formData);
    }

    GetDiscount(): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}agency/show-discount`);
    }

    // downloadPassenger(id) {
    //     const token = localStorage.getItem('currentUser');
    //     window.open(`${glob.BASE_URL}insurance/download-passenger/?task_id=` + id + '&access_token=' + token);
    // }

    // List Reservation
    changeMonthCalendar(formData): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/change-month-search-lowest-fare`, formData);
    }

    SeenNotification(): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}agency/seen-notifi-agency`);
    }


    // search fast
    SearchFast(formData): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/search-fast`, formData);
    }

}
