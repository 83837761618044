import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-reservation-search-head',
    templateUrl: './reservation-search-head.component.html',
    styleUrls: ['./reservation-search-head.component.css']
})
export class ReservationSearchHeadComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
