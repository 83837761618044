import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';

declare var $: any;

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit, OnChanges {
    @Input('dataNotifyShow') dataNotifyShow: any = [];
    public ShowData = true;

    constructor(private router: Router) {
    }

    ngOnChanges(): void {
        if (this.dataNotifyShow.length > 0) {
            this.ShowData = true;
        } else {
            this.ShowData = false;
        }
    }

    ngOnInit() {

    }

    GoToDetailFlight(id) {
        $('.close').trigger('click');
        this.router.navigate(['/reservation/history-search', {id: id}]);
    }

}
