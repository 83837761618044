import {EventEmitter, Injectable} from '@angular/core';
import * as glob from '../global_api';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ShareService {
    public eventEmitter: EventEmitter<any> = new EventEmitter();
    public permission: string = '';

    constructor(private _httpClient: HttpClient) {
    }

    refreshToken(): any {
        this._httpClient.post<any>(glob.BASE_URL + 'agency/refresh-token', {}, {observe: 'response'})
            .pipe(map(res => {
                console.log(res);
            }));
    }

}
