import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-select-flight-child',
    templateUrl: './select-flight-child.component.html',
    styleUrls: ['./select-flight-child.component.css']
})
export class SelectFlightChildComponent implements OnInit {

    @Input('DataFlight') DataFlight;
    @Input('IndexOfFlight') IndexOfFlight;
    @Output('SendInfoChangePrice') SendInfoChangePrice = new EventEmitter();
   
    @Output() sendData:EventEmitter<any> = new EventEmitter();
    public ChangePrice = {
        price: 0,
        code: '',
        position: '',
        index: -1
    };

    constructor() {
    }

    ngOnInit() {
        console.log(this.DataFlight.data);
    }

    getfareDeparture(e, i, index) {
        this.ChangePrice.price = e;
        this.ChangePrice.code = i;
        this.ChangePrice.index = index;
        this.ChangePrice.position = this.IndexOfFlight;
        this.SendInfoChangePrice.emit(this.ChangePrice);
    }
}
