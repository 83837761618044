import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import * as glob from '../global';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _httpClient: HttpClient
    ) {}


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // if (localStorage.getItem('currentUser')) {
        //     alert(1);
        //     return true;
        // }
        // this._router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        // return false;
        return this._httpClient.post<any>(glob.BASE_URL + 'agency/refresh-token', {}, {observe: 'response'})
            .pipe(map(res => {
                if (res.ok) {
                    localStorage.setItem('accessKey', res.body.message);
                    return true;
                } else {
                    this._router.navigate(['/auth'], {queryParams: {returnUrl: state.url}});
                    return false;
                }
            }));
    }
}

