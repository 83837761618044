import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-btn-loading',
    templateUrl: './btn-loading.component.html',
    styleUrls: ['./btn-loading.component.css']
})
export class BtnLoadingComponent implements OnInit {

    @Input('loading') loading: boolean = false;

    constructor() {
    }

    ngOnInit() {
    }

}
