import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {SearchReservService} from '../../../pages/reservations/reservation-searches/search-reserv.service';

declare var $: any;

@Component({
    selector: 'app-add-multi-city',
    templateUrl: './add-multi-city.component.html',
    styleUrls: ['./add-multi-city.component.css']
})
export class AddMultiCityComponent implements OnInit, OnChanges {

    public dataCityPair: any;
    public dataCityPair2: any;
    public showDelete = false;
    public dataArrr = {
        position: 0,
        code_de: '',
        code: '',
    };
    @Input('changeCity') changeCity: any = [];
    @Output('dataNextArrival') dataNextArrival = new EventEmitter();
    @Output('positionDeleteFlight') positionDeleteFlight = new EventEmitter();

    constructor(private searchReservService: SearchReservService) {
    }

    ngOnInit() {
        this.GetDataCityPair();
        if (this.changeCity === 1) {
            this.showDelete = false;
        } else {
            this.showDelete = true;
        }
    }

    ngOnChanges(): void {
    }

    changeCityPairMultiDePart(e) {
        this.searchReservService.GetCityPairArrival(e.target.value).subscribe(data => {
            this.dataCityPair2 = data.data;
            this.dataArrr.code_de = e.target.value;
        });
    }

    changeCityPairMultiReturn(e) {
        this.dataArrr.code = this.dataArrr.code_de + '-' + e.target.value;
        this.dataArrr.position = this.changeCity;
        this.dataNextArrival.emit(this.dataArrr);

    }

    DeleteFlight() {
        this.positionDeleteFlight.emit((this.changeCity));
    }

    GetDataCityPair() {
        this.searchReservService.GetCityPair().subscribe(res => {
            this.dataCityPair = res.data;
        }, error1 => {
            console.log(error1);
        });
    }
}
