import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-flight-info',
    templateUrl: './flight-info.component.html',
    styleUrls: ['./flight-info.component.css']
})
export class FlightInfoComponent implements OnInit {

    @Input('DataFlight') DataFlight;
    @Input('IndexOfFlight') IndexOfFlight;
    @Input('IndexFlightOption') IndexFlightOption;

    constructor() {
    }

    ngOnInit() {
        this.IndexFlightOption = 0;
        console.log(this.DataFlight);
        console.log(this.IndexFlightOption);
    }

}
