import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as glob from '../global';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public permission: string;

    constructor(private _httpClient: HttpClient) {
    }

    SeenNotification(): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}agency/seen-notifi-agency`);
    }
}
