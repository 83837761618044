import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingComponent} from '../_directives/loading/loading.component';
import {AuthService} from '../_service/auth.service';
import {ShareService} from './share.service';
import {ReservationSearchHeadComponent} from '../../modules/reservation/components/reservation-searches/reservation-search-head/reservation-search-head.component';
import {RouterModule} from '@angular/router';
import {BtnLoadingComponent} from '../../modules/reservation/components/btn-loading/btn-loading.component';
import {NotificationComponent} from '../../modules/reservation/components/notification/notification.component';
import {AddMultiCityComponent} from '../../modules/reservation/components/reservation-searches/add-multi-city/add-multi-city.component';
import {SelectFlightChildComponent} from '../../modules/reservation/components/reservation-searches/select-flight-child/select-flight-child.component';
import {FlightInfoComponent} from '../../modules/reservation/components/reservation-searches/flight-info/flight-info.component';
import {PipeSafehtml} from '../pipe/pipe.safehtml';


@NgModule({
    imports: [
        CommonModule,
        RouterModule

    ],
    declarations: [
        LoadingComponent,
        BtnLoadingComponent,
        PipeSafehtml,
        NotificationComponent,
        AddMultiCityComponent,
        SelectFlightChildComponent,
        FlightInfoComponent,
        ReservationSearchHeadComponent

    ],
    exports: [
        LoadingComponent,
        BtnLoadingComponent,
        PipeSafehtml,
        NotificationComponent,
        AddMultiCityComponent,
        SelectFlightChildComponent,
        FlightInfoComponent,
        ReservationSearchHeadComponent
    ],
    providers: [
        AuthService,
    ]
})
export class ShareModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ShareModule,
            providers: [ShareService]
        };
    }
}
